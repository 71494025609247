
.bgimage {
	min-height: calc(100vh - 74px);

	background-image: url('/assets/img/meeting2.jpg');
	background-size: cover;
	background-position: 50% 5%;
	background-repeat: none;
	z-index: 1;
	position: relative;
}

.bgimage:before {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom,rgba(30,100,100,.7) 0,rgba(46,117,173,.9) 100%);
    background-repeat: repeat-x;
    content: " ";	
}