#navbar {
	z-index: 999;

	height: 100%;
	width: 100%;
	position: fixed;

	top: 105px;
	left: 0;


	transform: translateX(-250px);
	transition: transform 600ms ease-in-out;
	
	width: 400px;
	height: 100%;
	background-color: #fff;
	padding: 20px;
	font-family: sans-serif;
	webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -5px;
    border-right: 1px solid rgb(217, 227, 237);
}

#navbar.slideIn {
	transform: translateX(0);
}

#navbar.slideOut {
	transform: translateX(-350px);
}

#navbar button {
	position: absolute;
	top: 10px;
	right: 5px;
	border: none;
}

/*#root {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

#App {
	width: 100%;
	height: 100%;
}*/