
.bg-inverse {
	background-color: #fff !important;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -5px;
    color: #2c5183;
	border-bottom: 1px solid rgb(217, 227, 237);
}

.navbar-brand {
	padding:15px 10px 10px 10px;
}

.navbar-collapse {
	margin-top: 0;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
	.navbar-collapse {
		margin-top: -6px;
	}
}

@media (min-width: 1200px) {

}




.navbar-nav .nav-link {
	padding-left: 0;
	padding-right: 0;
/*	margin-left: 15px;
	margin-right: 15px;
*/	margin-left: 12px;
	margin-right: 12px;
}


.navbar-inverse .navbar-nav .active>.nav-link, .navbar-inverse .navbar-nav .nav-link.active, .navbar-inverse .navbar-nav .nav-link.open, .navbar-inverse .navbar-nav .open>.nav-link {
    color: #2c5183;
    padding-bottom: 4px;
    border-bottom: 2px solid #d6669b;
}

.navbar-inverse .navbar-nav .nav-link:focus, .navbar-inverse .navbar-nav .nav-link:hover {
	color: #d6669b;
}


.navbar-inverse .navbar-nav .nav-link {
	color: #2c5183;
}


.navbar-toggleable-md .navbar-nav .nav-link {
	padding-left: 0.1rem;
	padding-right: 0.1rem;
	font-size: 0.9rem;
}


.icon-bell {
	padding-right: 0;
	margin-right: 0 !important;
	position: relative;
}

.icon-help .fa {
	margin-top: 4px;
	color: #506583;
	font-size: 1.4rem !important;
}

/*.icon-bell.active {
	border-bottom: 2px solid transparent;
}
*/
.icon-bell .fa {
	color: #ccc;
	font-size: 1.3rem !important;
    position: relative;
	margin-top: 4px;
	-ms-transform:rotate(35deg);
	-webkit-transform:rotate(35deg);
    -moz-transform: rotate(35deg);
    -o-transform: rotate(35deg);
	transform:rotate(35deg);
}

.notifications-count {
    /*background: #f6a623;*/
    background: #eb5b9a;
    border: 2px solid #fff;
    border-radius: 50%;
    display: block;
    height: 13px;
    position: absolute;
    right: -4px;
    top: 10px;
    width: 13px;
    z-index: 100;
}



.dropdown-menu-news .news-header {
padding-left: 15px;
padding-right: 15px;
}

.dropdown-menu-news p {
	font-size: 0.9rem;

}


.navbar-toggler-icon {
	background-color: #e0e0e0;
	margin-top: 6px;
	border-radius: 3px;
}




