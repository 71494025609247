.packages a:link {
   text-decoration: none;
}

.packages a:link {
   color:inherit;
}
.packages a:hover {
   color:inherit;
}


.package-panel {
	margin-top: 60px;
	padding-bottom: 20px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	/*border: 1px solid #eef6ff;*/
	min-height: 200px;
	background: #fff;
    position: relative;
    text-align: center;
	transition: all .2s ease-out;
}

.package-panel p {
	font-size: 18px;
	color: inherit;
}

.package-panel .package-img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    display: inline-block;
    margin-top: -45px;
    margin-bottom: 20px;	
	background: #fff;
	padding: 4px;
	transition: all .2s ease-out;
}

.packages a:hover .package-img {
	transform: scale(1.1);
}

.packages a:hover .package-panel {
	margin-top: 55px;
	background-color: rgba(180, 217, 183, 0.1);
	box-shadow: rgba(50, 50, 93, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
}


.package-panel.active {
	border: 1px solid rgba(44, 81, 131, .3);
}

.package-panel.active .package-img {
	border: 1px solid rgba(44, 81, 131, .3);
}

p.btn-primary {
	color: #fff;
}

p.btn-secondary {
	border: 1px solid #35517f;
	margin-top: 15px;
	margin-bottom: 15px;
}

.package-panel-minimal {
	min-height: 0;
	box-shadow: none;
	border: none;
}


.g-color--primary {
    color: #13b1cd !important;
}
.g-font-weight--400 {
    font-weight: 400 !important;
}

