.App-logo {
	animation: App-logo-spin infinite 30s linear;
	height: 80px;
}

body {
	background-color: #f9fafb;
}


.App {
/*	background-image: linear-gradient(-180deg, #58DBAB 0%, #2DBBC8 100%);
	min-height: 100vh;
*/
	padding-top: 70px;
}

.page-dashboard {
	padding-bottom: 60px;
}

button:focus {
		outline: none;
}

.Spinner {
	margin-top: 40px;
}




.text-muted {
	color: #838F97 !important;
}



@keyframes App-logo-spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}




.table-hover tbody tr:hover {
		background-color: rgba(180, 217, 183,.185);
}

.card {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px -5px;
		border-width: 1px;
		border-style: solid;
		/*overflow: hidden;*/
		border-radius: 5px;
		margin: 40px 0px 0px;

		color: rgb(61, 85, 107);
		background-color: rgb(255, 255, 255);
		border-color: rgb(217, 227, 237) !important;
}






.form-control::-webkit-input-placeholder { color: #ccc; }
.form-control:-moz-placeholder { color: #ccc; }
.form-control::-moz-placeholder { color: #ccc; }
.form-control:-ms-input-placeholder { color: #ccc; }

.input-group .form-control {
	z-index: 0;
}


.form-control-plaintext {
		padding-top: .5rem;
		padding-bottom: .5rem;
		margin-bottom: 0;
		line-height: 1.25;
		border: solid transparent;
		border-width: 1px 0;  
}

.form-control-plaintext:focus {
		padding-top: .5rem;
		padding-bottom: .5rem;
		margin-bottom: 0;
		line-height: 1.25;
		border: solid transparent;
		border-width: 1px 0;  
}


/* -- Modal -- */
.modal {
		display: block; 
		position: fixed; /* Stay in place */
		z-index: 2000; /* Sit on top */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		overflow: auto; /* Enable scroll if needed */
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0,0,0,0.4); 
		background-color: rgba(10,10,30,0.4); 
}

/* Modal Content/Box */
.modal-content {
		background-color: #fefefe;
		margin: 10% auto; 
		/*padding: 20px;*/
		border: 1px solid #888;
		width: 80%; 
}

.modal-body {
	
}

.modal-content h3 {
	margin-top: 15px;
}

.modal .footer {
	border-top: 1px solid #eee;
	margin-top: 15px;
	padding-top: 15px;
}

.modal .footer .btn {
	margin-left: 5px;
	margin-right: 5px;
}

/* The Close Button */
.close {
		color: #aaa;
		float: right;
		font-size: 28px;
		font-weight: bold;
}

.close:hover,
.close:focus {
		color: black;
		text-decoration: none;
		cursor: pointer;
}

/* -- /Modal -- */




/* Checkmark https://codepen.io/haniotis/pen/KwvYLO */

.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #7ac142;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
	width: 56px;
	height: 56px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #fff;
	stroke-miterlimit: 10;
	margin: 10% auto;
	box-shadow: inset 0px 0px 0px #7ac142;
	animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}



@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes scale {
	0%, 100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}
@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #7ac142;
	}
}

/* / Checkmark */



/* --- Icons --- */

.icon-header-opportunities {
	float: left;
	margin-right: 10px;
	/*background-color: #6B4E71;*/
	border-radius: 4px;
	padding: 7px;
	font-size: 22px;
	width: 44px;
	text-align: center;
	color: #6B4E71;
}




/* Bootstrap Callouts */
.bd-callout {
		padding: 1.25rem;
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
		border: 1px solid #eee;
		border-left-width: .25rem;
		border-radius: .25rem
}

.bd-callout h4, .bd-callout h5 {
		margin-top: 0;
		margin-bottom: .25rem
}

.bd-callout p:last-child {
		margin-bottom: 0
}

.bd-callout code {
		border-radius: .25rem
}

.bd-callout+.bd-callout {
		margin-top: -.25rem
}

.bd-callout-info {
		border-left-color: #5bc0de
}

.bd-callout-info h4, .bd-callout-info h5 {
		color: #5bc0de
}

.bd-callout-warning {
		border-left-color: #f0ad4e
}

.bd-callout-warning h4, .bd-callout-warning h5 {
		color: #f0ad4e
}

.bd-callout-danger {
		border-left-color: #d9534f
}

.bd-callout-danger h4, .bd-callout-danger h5 {
		color: #d9534f
}


blockquote {
	background: #f9f9f9;
	border-left: 2px solid #ccc;
	margin: 1.5em 10px;
	padding: 0.5em 10px;
	/*quotes: "\201C""\201D""\2018""\2019";*/
}
blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 2em;
	line-height: 0.1em;
	margin-right: 0.15em;
	vertical-align: -0.4em;
}
blockquote p {
	display: inline;
}


.pos-rel { position: relative; }
.pos-abs { position: absolute; }

.icon-upgrade {
	position: absolute;
	right: 2px;
	top: -2px;
	color: #ff5f85;
}

ul.readunread {
	list-style-type: none;  
}

li.li-read:before, li.li-unread:before {
	font-family: 'FontAwesome';
	font-size: 0.75em;
	content: '\f111';
	margin:0 10px 0 -15px;
}

li.li-read:before {
	color: #b1d9ba;
}

li.li-unread:before {
	color: #eb5b9a;
}


.text-tertiary {
	color: #2b517f;
}


.react-switch {
	text-align: left;
	margin-bottom: -14px;
	margin-left: 15px;
	margin-right: 15px;
}

.switch-period-text {
	font-weight: bold;
	font-size: 1.1em
}



.badge {
	margin-right: 2px;
	font-weight: 400;
	padding: 4px 6px;
}

 .badge-primary.badge-tag {
 	background-color: #8196bc;
/* 	background-color: #c2cad8;
 	border: 1px solid #8196bc;
 	color: #444;
*/ }

.clean-table.table th {
	border-top: 0;
	border-bottom: 0;
}



/* Contacts */

.page-contacts {
	/*padding-top: 40px;*/
	margin-bottom: 30px;
	font-size: 0.95em;		/* Use this to change row font size */
}

.card-pad-width {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 1.25rem;	
}

.navigation {
	background-color: #f0f4f7;
	padding: 15px 15px; 
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	margin: 15px -20px 10px  -20px;
}


.contact-listentry {
	background-color: #fff;
}

.contact-listentry .Avatar {
	margin-right: 12px;
}

.btn.disabled, .btn:disabled {
	background-color: #ddd;
	opacity: 0.3;
}

.status-DRAFT  {
	/*opacity: 0.4;*/
	/*color: rgba(200,200,200, 0.3) !important;*/
	background-color: #eee;
}


.formError {
	color: #ff0000;
}


.checkbox-select {
	
}





/* Toggleable add contact form */

.react-phone-number-input__icon {
	opacity: 0.6;
}

.has-feedback {
	position: relative;
}

.feedback-left .form-control {
	padding-left: 40px;

}

.feedback-left .form-control-feedback {
    left: 14px;
    top: 6px;
    position: absolute;
}

.feedback-left .form-control-feedback.feedback-gray  {
	color: #ccc;
}








/* Notes */

.note-entry {
}

.note-entry .title {
	margin-left: 15px;
	font-size: 0.8em;
	margin-top: -10px;
}

.note-entry .title i {
	font-weight: normal;
}

.note-entry .body {
	border: 1px solid #eee;
	padding: 15px;
	background-color: #fbfbfb;
	margin-bottom: 15px;
}


/* React Data Grid */

.react-grid-Cell {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}




/* Feedback forms (icons) */

.feedback-small .form-control {
	padding-left: 22px;

}
.feedback-small .form-control-feedback {
	font-size: 17px;
	top: 1.5px;
	left: 12px;
}



/* Forms */
.custom-checkbox {
	width: 1rem;
	height: 1rem;
}



.cell-truncate-150 {
	width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}



.btn.btn-link {
	cursor: pointer;
}

.btn.btn-link.btn-link-dark {
	color: #444;
}

.btn.btn-fancy {
    color: #fff !important;
    /*color: #3D556B;*/
    background: #77C9DE;
    /*border-width: 0;*/
    border-style: solid;
    border-color: #67BDCE;
    /*font-size: 18px;*/
    /*padding: .8rem 1.4rem;*/
    -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 1%), 0 1px 3px rgb(0 0 0 / 8%);
    box-shadow: 0 4px 6px rgb(50 50 93 / 1%), 0 1px 3px rgb(0 0 0 / 8%);
    transition: all .2s ease;	
}


.btn.btn-fancy:hover {
    /*background-color: #15c1e0;*/
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
}
/*
.btn.btn-secondary {
	background-color: #F5FAFE;
}

.btn.btn-secondary .fa {
	color: #e65c9c;
}

*/




h2 .header {
	font-weight: 600;
}




.filter-bar-advanced {
    margin-bottom: -14px;
    margin-top: 15px;	
    margin-left: 2px;
}

.filter-bar-advanced .toggle {
	background-color: #6D82A8;
	color: #fff;
	display: inline;
	font-size: 14px;
	padding: 4px 10px;
}



.sent-email-content img {
	width: 100%;
	height: auto;
}


/* Vendor */
.__react_component_tooltip { pointer-events: auto !important; }


/* BigCal */

.rbc-event {
	border-radius: 4px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	font-size: 0.8rem;
}

.rbc-toolbar .rbc-toolbar-label {
	font-weight: 600;
	font-size:  18px;
}

.rbc-off-range-bg {
	background-image: linear-gradient(45deg, #f5f5f5 25%, #efefeb 25%, #efefeb 50%, #f5f5f5 50%, #f5f5f5 75%, #efefeb 75%, #efefeb 100%);
	background-size: 10.00px 10.00px;
}

.rbc-date-cell {
	/*font-weight: 600;*/
	/*font-size: 18px;*/
}




.sticky-top {
	z-index: 0;
}




