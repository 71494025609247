
.checkout-form {
	margin-bottom: 40px;
	padding-bottom: 40px;
	/*border-bottom: 3px solid #e6ebf1;*/
}

.checkout-form button {
	white-space: nowrap;
	border: 0;
	outline: 0;
	display: inline-block;
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding: 0 14px;
	box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
	color: #fff;
	border-radius: 4px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	background-color: #667788;
	text-decoration: none;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	margin-top: 10px;
}

.checkout-form button:hover {
	color: #fff;
	cursor: pointer;
	background-color: #778899;
	transform: translateY(-1px);
	box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.checkout-form	.StripeElement {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	background: white;
	border: 1px solid rgba(0,0,0,.15);
}

.checkout-form .StripeElement--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

