

/* ------- Avatar ------- */

.Avatar {
	float: left;
	/*margin-right: 12px;*/
	/*border: 2px solid #c0cad7;*/
	border: 2px solid #8eb0dd;
	background-color: #F4FAF5;
	position: relative;
	width: 62px;
	height:62px;
	border-radius: 500px;
	text-align: center;
    /*overflow: hidden;*/
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;;
}

.Avatar-lg {
	width:100px;
	height: 100px;
}

.Avatar-sm {
	width:30px;
	height: 30px;
	border-width: 1px;

}


.Avatar img {
	/*border-radius: 500px;*/
	/*max-width: 60px;*/
}

.Avatar .initials {
	color: #fff;
	/*color: #c0cad7;*/
	text-align: center;
	line-height: 60px;
	font-size: 20px;
}

.Avatar-lg .initials {
	/*color: #c0cad7;*/
	text-align: center;
	line-height: 100px;
	font-size: 30px;
}

.Avatar-sm .initials {
	/*color: #c0cad7;*/
	text-align: center;
	line-height: 29px;
	font-size: 10px;
}

.Avatar .text {
	/*color: #c0cad7;*/
	color: #fff;
	text-align: center;
	line-height: 60px;
	font-size: 18px;
}

.Avatar-lg .text {
	/*color: #c0cad7;*/
	text-align: center;
	line-height: 100px;
	font-size: 26px;
}

.Avatar-sm .text {
	/*color: #c0cad7;*/
	text-align: center;
	line-height: 30px;
	font-size: 26px;
}

.Avatar .status {
	position: absolute;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
	width: 14px;
	height: 14px;
	margin: 2px;
	border-width: 2px;
	border-style: solid;
	border-radius: 100%;
	border-color: #fff;
	display: none;
}

.Avatar-lg .status {
    bottom: 2px;
    right: 2px;
    width: 20px;
    height: 20px;	
}

.Avatar .status--online {
	background-color: #d6669b;
	display: inline;
}

.Avatar .status--offline {
	background-color: #bbd7b9;
	display: inline;
}

/*
//offline: bbd7b9
//online: d6669b
//other: 35517f
//
*/